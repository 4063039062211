/* eslint-disable react/no-array-index-key */
import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import ContentModule from '../ContentModule';
import ModuleHero, { ModuleHeroPropTypes } from '../ModuleHero';

export const PageLandingPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  introHero: PropTypes.shape(ModuleHeroPropTypes),
  // eslint-disable-next-line react/forbid-prop-types
  contentSections: PropTypes.array.isRequired
};

function PageLanding({ _id, _contentTypeId, slug, introHero, contentSections }) {
  return (
    <div key="PageLanding" data-testid="PageLanding" {...sidekick(_id, null, _contentTypeId, 'Page Landing')}>
      {introHero
        ? (() => {
            return (
              <ModuleHero
                _id={introHero._id}
                _contentTypeId={introHero._contentTypeId}
                headerText={introHero.headerText}
                summaryText={introHero.summaryText}
                image={introHero.image}
                className={`theme-${slug}`}
              />
            );
          })()
        : null}

      {contentSections && contentSections.length ? (
        <div
          key="PageLanding-modules"
          data-testid="PageLanding-modules"
          {...sidekick(_id, 'contentSections', _contentTypeId, 'Content Sections')}>
          {contentSections.map((module, idx) => {
            return (
              <ContentModule
                wrapSection
                contentTypeId={module._contentTypeId}
                fields={module}
                key={`${module._id}-${idx}`}
                pageLandingSlug={slug}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
PageLanding.propTypes = PageLandingPropTypes;

PageLanding.defaultProps = {
  introHero: null
};

export default PageLanding;
